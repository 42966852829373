<!--  -->
<template>
  <div>
    <div class="top flex-box plr40 ptb20 flex-between">

      <img src="../assets/back.png" class="w40" alt="" @click="back">
     
      <img src="../assets/home.png" class="w40" alt="" @click="home">

    </div>
  </div>
</template>

<script>
export default {
  name: 'TopPage',
  data() {
    return {
    }
  },
  methods: {
    home() {
      this.$router.push('/');
    },
    back() {
      this.$router.go(-1);
    }
  }
}

</script>

<style scoped>
.w40 {
  cursor: pointer;
}

.top {}
</style>
