<template>
  <div id="app">
    <!-- <img alt="Vue logo" src="./assets/logo.png">
    <HelloWorld msg="Welcome to Your Vue.js App"/> -->
   
    <router-view></router-view>
    <!-- <div class="custom-font">ཏང་ཡོན་གྱི་ཆ་འཕྲིན་རེའུ་མིག</div> -->
  </div>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  components: {
    // HelloWorld
  }
}
</script>
<style>
/* 使用自定义字体 */
.custom-font {
  font-family: 'PingFangSC-zy';
}
</style>
<style>
/*每个页面公共css */
@import "../src/assets/style.css";
</style>
<style>
#app{
  margin: 0;
    padding: 0;
}
body{
  margin: 0;
    padding: 0;
}
.title,.title1{
    font-size: 25px;
}

</style>
