import Vue from 'vue'
import App from './App.vue'
// 将自动注册所有组件为全局组件
import dataV from '@jiaminghi/data-view'
import router from "./router";

Vue.use(dataV)
import ElementUI from 'element-ui';

import 'element-ui/lib/theme-chalk/index.css';
import Vue2OrgTree from 'vue-tree-color';
Vue.use(Vue2OrgTree)

Vue.use(ElementUI);
//引入echarts
// import * as echarts from 'echarts';
// //  创建一个名为 $echarts 的全局变量
// Vue.prototype.$echarts = echarts
Vue.config.productionTip = false

import store from './store'
// 在 main.js 中引入
import '@/assets/font.css'
new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app')