var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.show)?_c('div',{staticClass:"bg"},[_c('TopVue'),_c('div',{staticClass:"menu"},[_c('div',{staticClass:"menu_boxs"},[_c('div',{staticClass:"menu_box",on:{"click":function($event){return _vm.pathto('/MenuOneVue')}}},[_vm._m(0),_vm._m(1)]),_c('div',{staticClass:"menu_box",on:{"click":function($event){return _vm.pathto('/MenuTwoVue')}}},[_vm._m(2),_vm._m(3)]),_c('div',{staticClass:"menu_box",on:{"click":function($event){return _vm.pathto('/MenuThreeVue')}}},[_vm._m(4),_vm._m(5)])]),_c('div',{staticClass:"menu_boxs1"},[_c('div',{staticClass:"menu_box1",on:{"click":function($event){return _vm.pathto('/MenuFourVue')}}},[_vm._m(6),_vm._m(7)]),_c('div',{staticClass:"menu_box1",on:{"click":function($event){return _vm.pathto('/MenuFiveVue')}}},[_vm._m(8),_vm._m(9)])])])],1):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box_t"},[_c('img',{staticClass:"box_t_img",attrs:{"src":require("../assets/menu/menu1.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box1_m"},[_c('img',{staticClass:"me",attrs:{"src":require("../assets/menu/me1.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box_t"},[_c('img',{staticClass:"box_t_img",attrs:{"src":require("../assets/menu/menu2.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box1_m"},[_c('img',{staticClass:"me",attrs:{"src":require("../assets/menu/me2.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box_t"},[_c('img',{staticClass:"box_t_img",attrs:{"src":require("../assets/menu/menu3.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box1_m"},[_c('img',{staticClass:"me",attrs:{"src":require("../assets/menu/me3.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box1_t"},[_c('img',{staticClass:"box_t_img",attrs:{"src":require("../assets/menu/menu4.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box1_m"},[_c('img',{staticClass:"me",attrs:{"src":require("../assets/menu/me4.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box1_t"},[_c('img',{staticClass:"box_t_img",attrs:{"src":require("../assets/menu/menu5.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box1_m"},[_c('img',{staticClass:"me",attrs:{"src":require("../assets/menu/me5.png"),"alt":""}})])
}]

export { render, staticRenderFns }