import Vue from 'vue'
import Vuex from 'vuex'
// 应用vuex插件
Vue.use(Vuex)
 
// 创建并暴露store
export default new Vuex.Store({
    // 数据,相当于data
    state: { 
        sum: 0,
        school:"尚硅谷",
        subject:"前端",

    },
    //准备getters——用于将state中的数据进行加工
    getters: {

        bigSum(state){
            return state.sum * 10
        }
 
    },
    //准备mutations——用于操作数据（state）
    mutations: { 

        add(state, value) {
            state.sum += value
        },
        reduce(state,value){
            state.sum -= value
        }
    
    },
    //准备actions——用于响应组件中的动作
    actions: {
        
    },
    modules: {}
})