<template>
    <div class="bg">
        <TopVue></TopVue>

        <div class="form_boxs">
            <div class="title" style="text-align: center; margin-top: -50px;margin-bottom: 10px;">
                <img src="../assets/menu/m1.png" alt="" class="title_img">
                <!-- 党员信息表 -->
            </div>
            <div class="hello form_box" v-if="show">


                <div class="back" @click='show = false'><img src="../assets/back.png" class="back1" alt="">返回</div>

                <div>
                    <el-card class="box-card">
                        <!-- 用户列表区域 -->
                        <el-table :data="userList1" :stripe="true" :border="true" style="width: 100%;height:60vh">
                            <el-table-column type="index"></el-table-column>
                            <el-table-column prop="username" label="姓名"></el-table-column>
                            <el-table-column prop="bianhao" label="编号"></el-table-column>
                            <el-table-column prop="dangname" label="所在党组"></el-table-column>
                            <el-table-column prop="age" label="年龄"></el-table-column>
                            <el-table-column prop="sex" label="性别"></el-table-column>
                            <el-table-column prop="minzu" label="民族"></el-table-column>
                            <el-table-column prop="xueli" label="学历"></el-table-column>
                            <el-table-column prop="type" label="党内职务"></el-table-column>
                            <el-table-column prop="time1" label="入党申请时间"></el-table-column>
                            <el-table-column prop="time2" label="转正时间"></el-table-column>
                            <el-table-column prop="status" label="党籍状态"></el-table-column>
                        </el-table>
                        <!-- 分页区域 -->
                        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                            :current-page="queryInfo.pagenum" :page-sizes="[1, 2, 5, 10]"
                            :page-size="queryInfo.pagesize" layout="total, sizes, prev, pager, next, jumper"
                            :total="total">
                        </el-pagination>
                    </el-card>


                </div>


            </div>
            <div class="form_box hello" v-else>


                <div class="form_boxss">
                    <div class="form_boxss1" v-for="(item, index) in userList" :key="index" @click="xiangqing(index)">
                        <img src="../assets/t1.png" class="form_box_img" alt="">
                        <div class="form_box_text">
                            <div class="form_box_text_name">
                                姓名：{{ item.username }}
                            </div>
                            <div class="form_box_text_name">
                                职务：{{ item.type }}
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    </div>
</template>

<script>
import TopVue from '../components/Top.vue'

export default {
    components: {
        TopVue
    },
    data() {

        return {
            show: false,
            queryInfo: {
                // 查询参数
                query: '',
                // 当前的页码数
                pagenum: 1,
                // 每页显示多少条数据
                pagesize: 2
            },
            // 获取的用户列表
            //   userList: [],
            // 总数
            total: 0, userList1: [],
            userList: [{

                username: '王小虎',
                minzu: '汉',
                bianhao: 200333,
                dangname: '党组名称',
                age: '25',
                sex: '男',
                xueli: "本科",
                type: '党内职务',
                time1: '2012-11-10',
                time2: '2013-11-10',
                status: '党籍状态'

            }, {

                username: '王二虎',
                minzu: '汉',

                bianhao: 200333,
                dangname: '党组名称',
                age: '25',
                sex: '男',
                xueli: "本科",
                type: '党内职务',
                time1: '2012-11-10',
                time2: '2013-11-10',
                status: '党籍状态'

            }, {

                username: '王小虎',
                minzu: '汉',

                bianhao: 200333,
                dangname: '党组名称',
                age: '25',
                sex: '男',
                xueli: "本科",
                type: '党内职务',
                time1: '2012-11-10',
                time2: '2013-11-10',
                status: '党籍状态'

            }, {

                username: '王二虎',
                minzu: '汉',

                bianhao: 200333,
                dangname: '党组名称',
                age: '25',
                sex: '男',
                xueli: "本科",
                type: '党内职务',
                time1: '2012-11-10',
                time2: '2013-11-10',
                status: '党籍状态'

            }, {

                username: '王小虎',
                minzu: '汉',

                bianhao: 200333,
                dangname: '党组名称',
                age: '25',
                sex: '男',
                xueli: "本科",
                type: '党内职务',
                time1: '2012-11-10',
                time2: '2013-11-10',
                status: '党籍状态'

            }, {

                username: '王二虎',
                minzu: '汉',

                bianhao: 200333,
                dangname: '党组名称',
                age: '25',
                sex: '男',
                xueli: "本科",
                type: '党内职务',
                time1: '2012-11-10',
                time2: '2013-11-10',
                status: '党籍状态'

            }, {

                username: '王小虎',
                minzu: '汉',

                bianhao: 200333,
                dangname: '党组名称',
                age: '25',
                sex: '男',
                xueli: "本科",
                type: '党内职务',
                time1: '2012-11-10',
                time2: '2013-11-10',
                status: '党籍状态'

            }, {

                username: '王二虎',
                minzu: '汉',

                bianhao: 200333,
                dangname: '党组名称',
                age: '25',
                sex: '男',
                xueli: "本科",
                type: '党内职务',
                time1: '2012-11-10',
                time2: '2013-11-10',
                status: '党籍状态'

            }, {

                username: '王小虎',
                minzu: '汉',

                bianhao: 200333,
                dangname: '党组名称',
                age: '25',
                sex: '男',
                xueli: "本科",
                type: '党内职务',
                time1: '2012-11-10',
                time2: '2013-11-10',
                status: '党籍状态'

            }, {

                username: '王二虎',
                minzu: '汉',

                bianhao: 200333,
                dangname: '党组名称',
                age: '25',
                sex: '男',
                xueli: "本科",
                type: '党内职务',
                time1: '2012-11-10',
                time2: '2013-11-10',
                status: '党籍状态'

            }, {

                username: '王小虎',
                minzu: '汉',

                bianhao: 200333,
                dangname: '党组名称',
                age: '25',
                sex: '男',
                xueli: "本科",
                type: '党内职务',
                time1: '2012-11-10',
                time2: '2013-11-10',
                status: '党籍状态'

            }, {

                username: '王二虎',
                minzu: '汉',

                bianhao: 200333,
                dangname: '党组名称',
                age: '25',
                sex: '男',
                xueli: "本科",
                type: '党内职务',
                time1: '2012-11-10',
                time2: '2013-11-10',
                status: '党籍状态'

            }, {

                username: '王小虎',
                minzu: '汉',

                bianhao: 200333,
                dangname: '党组名称',
                age: '25',
                sex: '男',
                xueli: "本科",
                type: '党内职务',
                time1: '2012-11-10',
                time2: '2013-11-10',
                status: '党籍状态'

            },
            {

                username: '王二虎',
                minzu: '汉',

                bianhao: 200333,
                dangname: '党组名称',
                age: '25',
                sex: '男',
                xueli: "本科",
                type: '党内职务',
                time1: '2012-11-10',
                time2: '2013-11-10',
                status: '党籍状态'

            }, {

                username: '王小虎',
                minzu: '汉',

                bianhao: 200333,
                dangname: '党组名称',
                age: '25',
                sex: '男',
                xueli: "本科",
                type: '党内职务',
                time1: '2012-11-10',
                time2: '2013-11-10',
                status: '党籍状态'

            }, {

                username: '王小虎',
                minzu: '汉',

                bianhao: 200333,
                dangname: '党组名称',
                age: '25',
                sex: '男',
                xueli: "本科",
                type: '党内职务',
                time1: '2012-11-10',
                time2: '2013-11-10',
                status: '党籍状态'

            }, {

                username: '王小虎',
                minzu: '汉',

                bianhao: 200333,
                dangname: '党组名称',
                age: '25',
                sex: '男',
                xueli: "本科",
                type: '党内职务',
                time1: '2012-11-10',
                time2: '2013-11-10',
                status: '党籍状态'

            }, {

                username: '王小虎',
                minzu: '汉',

                bianhao: 200333,
                dangname: '党组名称',
                age: '25',
                sex: '男',
                xueli: "本科",
                type: '党内职务',
                time1: '2012-11-10',
                time2: '2013-11-10',
                status: '党籍状态'

            }, {

                username: '王小虎',
                minzu: '汉',

                bianhao: 200333,
                dangname: '党组名称',
                age: '25',
                sex: '男',
                xueli: "本科",
                type: '党内职务',
                time1: '2012-11-10',
                time2: '2013-11-10',
                status: '党籍状态'

            }, {

                username: '王虎',
                minzu: '汉',

                bianhao: 200333,
                dangname: '党组名称',
                age: '25',
                sex: '男',
                xueli: "本科",
                type: '党内职务',
                time1: '2012-11-10',
                time2: '2013-11-10',
                status: '党籍状态'

            }, {

                username: '王小虎',
                minzu: '汉',

                bianhao: 200333,
                dangname: '党组名称',
                age: '25',
                sex: '男',
                xueli: "本科",
                type: '党内职务',
                time1: '2012-11-10',
                time2: '2013-11-10',
                status: '党籍状态'

            }, {

                username: '王小虎',
                minzu: '汉',

                bianhao: 200333,
                dangname: '党组名称',
                age: '25',
                sex: '男',
                xueli: "本科",
                type: '党内职务',
                time1: '2012-11-10',
                time2: '2013-11-10',
                status: '党籍状态'

            }, {

                username: '王二虎',
                minzu: '汉',

                bianhao: 200333,
                dangname: '党组名称',
                age: '25',
                sex: '男',
                xueli: "本科",
                type: '党内职务',
                time1: '2012-11-10',
                time2: '2013-11-10',
                status: '党籍状态'

            }, {

                username: '王小虎',
                minzu: '汉',

                bianhao: 200333,
                dangname: '党组名称',
                age: '25',
                sex: '男',
                xueli: "本科",
                type: '党内职务',
                time1: '2012-11-10',
                time2: '2013-11-10',
                status: '党籍状态'

            }, {

                username: '王小虎',
                minzu: '汉',

                bianhao: 200333,
                dangname: '党组名称',
                age: '25',
                sex: '男',
                xueli: "本科",
                type: '党内职务',
                time1: '2012-11-10',
                time2: '2013-11-10',
                status: '党籍状态'

            },
            ],
            currentPage: 1,
            users: [
                // ... 用户数据，例如 [ { id: 1, name: '...', email: '...' }, ... ]
            ],
            cout: 0,
            pageSize: 10,
            currentPage1: 5,
            currentPage2: 5,
            currentPage3: 5,
            currentPage4: 4
        }
    },
    computed: {
        // 计算当前页的用户
        paginatedUsers() {
            const start = (this.currentPage - 1) * this.pageSize;
            const end = start + this.pageSize;
            return this.users.slice(start, end);
        },
        // 计算总页数
        totalPages() {
            return Math.ceil(this.users.length / this.pageSize);
        }

    },
    created() {
        this.getUserList()
    },
    methods: {
        xiangqing(index) {
            this.cout = index
            this.userList1 = []
            this.userList1 = this.userList1.concat(this.userList[this.cout])
            this.show = true
        },
        nextPage() {
            if (this.currentPage < this.totalPages) this.currentPage++;
        },
        prevPage() {
            if (this.currentPage > 1) this.currentPage--;
        },

        async getUserList() {
            // const { data: res } = await this.$http.get('users', {
            //     params: this.queryInfo
            // })
            // if (res.meta.status !== 200) {
            //     return this.$message('获取用户列表失败！')
            // }
            // this.userList = res.data.users
            this.total = 2
            // console.log(res)
        },
        // 监听 pageSize 改变的事件
        handleSizeChange(newSize) {
            //  将监听接受到的每页显示多少条的数据 newSzie 赋值给 pagesize
            this.queryInfo.pagesize = newSize
            //  修改完以后，重新发起请求获取一次数据
            this.getUserList()
        },
        // 监听 页码值  改变的事件
        handleCurrentChange(newPage) {
            //  将监听接受到的页码值的数据 newPage 赋值给 pagenum
            this.queryInfo.pagenum = newPage
            //  修改完以后，重新发起请求获取一次数据
            this.getUserList()
        },
        // 监听 switch 开关状态的改变
        async userStateChange(userInfo) {
            console.log(userInfo)
            const { data: res } = await this.$http.put(`users/${userInfo.id}/state/${userInfo.mg_state}`)
            if (res.meta.status !== 200) {
                // 更新失败，将状态返回初始状态
                this.userInfo.mg_state = !this.userInfo.mg_state
                this.$message.error('更新用户状态失败！')
            }
            this.$message.success('更新用户状态成功！')
        }
    }
}

</script>

<style scoped>
.back1 {
    width: 40px;
    height: 40px;

}

.back {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.hello {
    height: 80vh;
    /* 设置一个固定高度 */
    overflow-y: auto;
    background-color: #fff;
    /* 开启垂直滚动条 */
}

.bg {
    margin: 0;
    width: 100vw;
    height: 100vh;
    background-image: url(../assets/bg1.jpg);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: fixed;
}

.form_boxs {
    padding: 0 50px;


}

.title {
    text-align: center;
    margin: 0 auto;
}

.title,
.title1 {
    font-size: 25px;
}

.title_img {
    width: auto;
    height: 50px;
}

.form_box {
    text-align: center;
    border-radius: 10px;
    padding: 20px;

    background-color: rgba(255, 255, 255, 0.5);
}

.form_boxss {
    width: 100%;
    opacity: 1;

    opacity: 1;
    display: grid;
    text-align: center;
    grid-template-columns: repeat(8, 1fr);
    /* grid-row-gap: 20rpx; */
    grid-column-gap: 20rpx;
}

.form_boxss1 {
    /* width:14%; */
    margin: 20px;
    background-color: #fff;
    border-radius: 10px;
    padding-bottom: 10px;
    /* margin-bottom: 20px; */
}

.form_box_img {
    width: 100%;
    height: auto;
    border-radius: 10px 10px 0 0;
}
</style>
