<!--  -->
<template>
    <div class="content bg" @click="childpage()">
        <!-- 屏幕宽度: {{ screenWidth }} -->


    </div>
</template>

<script>



import * as echarts from 'echarts';
export default {
    name: 'HomePage',
    props: {
        msg: String
    },
    data() {
        return {
            screenWidth: 0,
        };
    },


    mounted() {
        this.initCharts();  //绘制图表
        this.handleResize(); // 初始化宽度
        window.addEventListener('resize', this.handleResize);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.handleResize);
    },

    methods: {
        childpage() {
            this.$router.push('/MenuPageVue');
        },
        handleResize() {
            this.screenWidth = window.innerWidth;
            console.log('screenWidth', this.screenWidth)
        },

        initCharts() {
            // 基于准备好的dom，初始化echarts实例
            var myChart = echarts.init(document.getElementById("barChart"));
         

            // 指定图表的配置项和数据
            var option = {
                title: {
                    text: "ECharts 入门示例",
                },
                tooltip: {},
                legend: {
                    data: ["销量"],
                },
                xAxis: {
                    data: ["衬衫", "羊毛衫", "雪纺衫", "裤子", "高跟鞋", "袜子"],
                },
                yAxis: {},
                series: [
                    {
                        name: "销量",
                        type: "bar",
                        data: [5, 20, 36, 10, 10, 20],
                    },
                ],
            };

            // 使用刚指定的配置项和数据显示图表。
            myChart.setOption(option);
        },
    },
};
</script>

<style>
.module_box {
    display: flex;
}
</style>
<style scoped>
.bg {
    width: 100vw;
    height: 100vh;
    background-image: url(../assets/bg.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: fixed;
    /* 充满全屏 */

}
</style>
