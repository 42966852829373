import Vue from "vue";
import VueRouter from "vue-router";
import HomePage from '../components/HomePage.vue';
import MenuPage from '@/components/MenuPage';
import MenuOne from '@/menupage/MenuOne';
import MenuTwo from '@/menupage/MenuTwo';
import MenuThree from '@/menupage/MenuThree';
import MenuFour from '@/menupage/MenuFour';
import MenuFive from '@/menupage/MenuFive';
// 注册vue-router中的所有组件
Vue.use(VueRouter);

// const allRouter = [...routers, ...echartsRouters];
const router = new VueRouter({
  mode: "hash",
  routes: [
    {
      path: '/',
      name: 'HomePage',
      component: HomePage,
      
    },
    {
      path: '/MenuPageVue',
      name: 'MenuPage',
      component: MenuPage,
      
    },
    {
      path: '/MenuOneVue',
      name: 'MenuOne',
      component: MenuOne,
      
    },
    {
      path: '/MenuTwoVue',
      name: 'MenuTwo',
      component: MenuTwo,
      
    },
    {
      path: '/MenuThreeVue',
      name: 'MenuThree',
      component: MenuThree,
      
    },
    {
      path: '/MenuFourVue',
      name: 'MenuFour',
      component: MenuFour,
      
    },
    {
      path: '/MenuFiveVue',
      name: 'MenuFive',
      component: MenuFive,
      
    }
    
    
    // ...其他路由
  ]
//   routes: allRouter
});

export default router;

