<!--  -->
<template>
    <div class="bg" v-if="show">
        <TopVue></TopVue>
        <div class="menu">
            <div class="menu_boxs">
                <div class="menu_box" @click="pathto('/MenuOneVue')">
                    <div class="box_t">
                        <img src="../assets/menu/menu1.png" class="box_t_img" alt="">
                        <!-- <img :src="item.icon" class="box_t_img" alt=""> -->
                    </div>
                    <div class="box1_m">
                        <img src="../assets/menu/me1.png"  alt="" class="me">
                    </div>

                </div>
                <div class="menu_box" @click="pathto('/MenuTwoVue')">
                    <div class="box_t">
                        <img src="../assets/menu/menu2.png" class="box_t_img" alt="">
                        <!-- <img :src="item.icon" class="box_t_img" alt=""> -->
                    </div>
                    <div class="box1_m">
                        <img src="../assets/menu/me2.png"  alt="" class="me">
                    </div>

                </div>
                <div class="menu_box" @click="pathto('/MenuThreeVue')">
                    <div class="box_t">
                        <img src="../assets/menu/menu3.png" class="box_t_img" alt="">
                        <!-- <img :src="item.icon" class="box_t_img" alt=""> -->
                    </div>
                    <div class="box1_m">
                        <img src="../assets/menu/me3.png"  alt="" class="me">
                    </div>

                </div>
            </div>
            <div class="menu_boxs1">
                
                <div class="menu_box1" @click="pathto('/MenuFourVue')">
                    <div class="box1_t">
                        <img src="../assets/menu/menu4.png" class="box_t_img" alt="">
                        <!-- <img :src="item.icon" class="box_t_img" alt=""> -->
                    </div>
                    <div class="box1_m">
                        <img src="../assets/menu/me4.png"  alt="" class="me">
                    </div>
                

                </div>
                <div class="menu_box1" @click="pathto('/MenuFiveVue')">
                    <div class="box1_t">
                        <img src="../assets/menu/menu5.png" class="box_t_img" alt="">
                        <!-- <img :src="item.icon" class="box_t_img" alt=""> -->
                    </div>
                    <div class="box1_m">
                        <img src="../assets/menu/me5.png"  alt="" class="me">
                    </div>

                </div>

            </div>
        </div>

    </div>
</template>

<script>
import TopVue from './Top.vue'
// import TopVue
export default {
    name: 'MenuPage',
    data() {
        return {
            show:false,
            screenWidth: document.body.clientWidth,//初始化宽度
            screenHeight: document.body.innerHeight,//初始化宽度

            menuitems: [

            ]
        }
    },
    components: {
        TopVue
    },
  
    mounted() {
        const that = this
        that.show=true
        window.onresize = () => {
            return (() => {
                window.screenWidth = document.body.clientWidth
                window.screenHeight = document.body.innerHeight
                that.screenWidth = window.screenWidth
                that.screenHeight = window.innerHeight
            })()
        }
        // console.log(this.$route.params.houseid,'hhhhhhhhhhhhhhhhhhhhhhhhhhhhh'); // 输出路由参数
    },

    watch: {

        screenWidth(val) {
            // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
            this.screenWidth = val
            // this.timer = true
        },
        screenHeight(val) {
            // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
            this.screenHeight = val
            // this.timer = true
        }
    },
    methods: {
        pathto(url) {
            this.$router.push(url);
        },
    }
}

</script>

<style scoped>
.bg {
    margin: 0;
    width: 100vw;
  height: 100vh;
    background-image: url(../assets/bg1.jpg);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: fixed;
}

.menu_boxs {
    /* margin: 100px; */
    /* text-align: center; */
  
    display: flex;
  align-items: center;
  justify-content: space-between;
}

.menu {
    padding:50px 200px;
    /* padding-top: 50px; */
}

.menu_boxs1 {
    /* margin: 100px; */
    text-align: center;
    margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}


.menu_box {
    cursor: pointer;
    text-align: center;
}

.menu_box1 {
    /* width: 40%; */
 
    text-align: center;
    cursor: pointer;
}

.box_t_img {
    width: 10vw;
    height: 10vw;
}

.box_m,
.box1_m {
    font-size: 24px;
    margin-top: 10px;
}

.box_b,
.box1_b {
    font-size: 24px;
    font-family: 'PingFangSC-zy';
}
.me{
    height: 70px;
    width: auto;
}
</style>
